@media (max-width: 560px) {
  .btn-lg {
    font-size: 0.7em !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: 9em;
  }

  .nav-pills .nav-link,
  .nav-pills .nav-item.show .nav-link {
    font-size: 0.8em;
  }
  .table-container {
    padding: 1em;
  }

  .info-container {
    top: 10em;
    width: auto;
  }
  .chart-bottom-title {
    bottom: 1%;
  }
}

@media (max-width: 767px) {
  /* main container */
  .main-right-container h1 {
    font-size: 2em;
  }

  .table-header {
    display: block;
  }
  .main-right-container p {
    font-size: 0.8em;
  }

  .faq-container {
    padding: 0em;
  }
  .faq-container h2 {
    font-size: 1.3em;
    margin-top: 2em;
  }

  .question-container {
    padding: 2em 0;
  }

  .back-btn {
    display: block;
    margin-bottom: 1em;
  }

  .nav-item.back-btn-list {
    display: none;
  }
  .selectyear-container {
    width: 100%;
  }

  .btn-table-header .fleet,
  .btn-table-header .add-vessel {
    margin-top: 1em;
    margin-right: 0.5em;
  }

  .edit-revenue-container {
    display: block;
    width: auto;
    left: 0;
  }

  .edit-revenue .close {
    right: -3%;
    top: -50%;
  }
}

@media (max-width: 999px) {

  .table-header h2 {
    font-size: 25px;
  }

  .nav {
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  .map-conatiner {
    height: 500px;
  }
}

@media (max-width: 1070px) {

  .route-detail-container {
    border: none;
  }
  .responsive-table {
    margin-bottom: 1em;
  }
}

@media (max-width: 1199px) {
  .table-content-container .vessel-detail-container .aux-list {
    padding-left: 1em;
  }
  .table-recommandation {
    width: 60em;
  }

  .main-container .login-image {
    width: 40%;
  }
}

@media (max-width: 1440px) {
  .table-content-container .vessel-detail-container .key,
  .table-content-container .vessel-detail-container .value {
    font-size: 0.8em;
  }

  .route-detail-container > div > div {
    padding: 0;
  }

  .table-header div {
    flex-wrap: wrap;
  }
}

@media (min-width: 1500px) {
  .modal-xl {
    --bs-modal-width: 1520px;
  }
}
@media (max-width: 1520px) {
  .add-fuel-container .fuel-template-container .fuel-template {
    flex-wrap: wrap;
  }
  .add-fuel-container .fuel-template-container .fuel-template .left-container,
  .add-fuel-container .fuel-template-container .fuel-template .right-container {
    width: 100%;
  }
  .add-fuel-container .fuel-template-container .fuel-template .left-container {
    border: none;
  }
}
@media (max-width: 1500px) {
  .route-detail-container .key,
  .route-detail-container .value {
    font-size: 0.8em;
  }

  .route-detail-container > div > div {
    padding: 0;
  }
}

@media (max-width: 1650px) {
  .price-scenario-container .price-scenario-tab-container .nav {
    padding-left: 45px;
    width: 20%;
  }

  .price-scenario-container .price-scenario-tab-container .tab-content {
    padding: 0;
    width: 80%;
  }

  .eu-content-container .input-container .first-container .cost-of-compliance {
    width: 65%;
  }

  .eu-content-container .input-container .growth-pa,
  .eu-content-container .input-container .first-container .base-year {
    width: 35%;
  }
}

@media (max-width: 1499px) {
  .eu-content-container .input-container .first-container {
    flex-wrap: wrap;
  }
  .eu-content-container .input-container .first-container .cost-of-compliance {
    width: 100%;
  }

  .eu-content-container .input-container .growth-pa,
  .eu-content-container .input-container .first-container .base-year {
    width: 100%;
  }
}

@media (max-width: 1340px) {
  .price-scenario-container .price-scenario-tab-container .nav {
    padding-left: 45px;
    width: 30%;
  }

  .price-scenario-container .price-scenario-tab-container .tab-content {
    padding: 0;
    width: 70%;
  }

  .refine-price-container .refine-price-tab-header {
    flex-wrap: wrap;
  }
  .refine-price-container .refine-price-tab-header .action-container {
    display: flex;
  }
  .refine-price-container .refine-price-tab-header .action-container {
    width: 100%;
  }
  .refine-price-container .refine-price-tab-header .action-container .priceby,
  .refine-price-container
    .refine-price-tab-header
    .action-container
    .fuel-origin {
    width: 50%;
  }
}

@media (max-width: 1280px) {
  .table-content-container {
    flex-wrap: wrap;
  }
  .table-content-container .vessel-detail-main-container {
    flex-flow: column;
  }
  .table-content-container .vessel-detail-container,
  .table-content-container
    .vessel-detail-main-container
    .route-action-container {
    width: 100%;
  }

  .btn-table-header {
    background-color: transparent;
    border: none;
    align-items: end;
  }
  .legislativ-scenario-container .scenario-container .select-scenario {
    width: 60%;
  }
}

@media (max-width: 1180px) {
  .imo-container .carbon-input-container .levy-amount-container,
  .imo-container .carbon-input-container .start-year-container {
    width: 50%;
  }
}

@media (max-width: 991px) {
  .main-left-container .login-image {
    text-align: center;
    width: 100%;
  }

  .login_left_container .login-image {
    align-items: center;
    width: 100%;
  }
  .main-right-container {
    text-align: center;
  }

  .legislativ-scenario-container .scenario-container,
  .imo-container .carbon-input-container,
  .imo-chart-container,
  .eu-content-container {
    flex-wrap: wrap;
  }

  .legislativ-scenario-container .scenario-container .select-scenario,
  .legislativ-scenario-container .scenario-container span,
  .legislativ-scenario-container .scenario-container .scenario-add-container,
  .imo-container .carbon-input-container .levy-amount-container,
  .imo-container .carbon-input-container .start-year-container,
  .imo-chart-container .cii-chart,
  .imo-chart-container .intensity-limit,
  .imo-chart-container .cii-chart .z-factor-input,
  .imo-chart-container .intensity-limit .penlty-input,
  .eu-content-container .eu-ets-container,
  .eu-content-container .eu-fm-container {
    width: 100%;
  }

  .price-scenario-container .price-scenario-tab-container .tab-data {
    flex-flow: column;
  }

  .price-scenario-container .price-scenario-tab-container .nav {
    padding-left: 45px;
    width: 100%;
  }

  .price-scenario-container .price-scenario-tab-container .tab-content {
    padding: 0;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .table-content-container {
    flex-flow: column;
  }

  .table-content-container
    .vessel-detail-main-container
    .route-action-container
    .vessel-detail-container:nth-child(1),
  .table-content-container
    .vessel-detail-main-container
    .route-action-container
    .vessel-detail-container:nth-child(2) {
    width: 100%;
  }

  .main-container {
    flex-flow: column;
  }
  .main-container .login-image {
    width: 100%;
    height: 450px;
  }
  .main-container .login-image img {
    object-fit: contain;
  }
  .main-container .main-container-actions {
    width: 100% !important;
    justify-content: center;
  }
  .add-fuel-container .fuel-template-container .first-select-container,
  .add-fuel-container .fuel-template-container .second-input-container,
  .selected-basin-data,
  .price-deltas-container .price-selection-container {
    flex-wrap: wrap;
  }
  .add-fuel-container
    .fuel-template-container
    .first-select-container
    .fuel-family-container,
  .add-fuel-container
    .fuel-template-container
    .first-select-container
    .fuel-origin-container,
  .add-fuel-container
    .fuel-template-container
    .second-input-container
    .base-year-input,
  .add-fuel-container
    .fuel-template-container
    .second-input-container
    .inflation-rate-input,
  .selected-basin-data .basin-name,
  .selected-basin-data .price,
  .price-deltas-container .price-selection-container .select-basin-container,
  .price-deltas-container .price-selection-container .basin-value {
    width: 100%;
  }

  .fuel-template-container .add-template-action {
    justify-content: center;
  }

  .refine-price-container .refine-price-tab-header .action-container {
    flex-wrap: wrap;
  }

  .refine-price-container .refine-price-tab-header .price-scenario,
  .refine-price-container .refine-price-tab-header .basin-list,
  .refine-price-container .refine-price-tab-header .fuel-family,
  .refine-price-container .refine-price-tab-header .action-container {
    width: 100%;
  }
}

@media (max-width: 567px) {
  .price-scenario-tab-container .nav-link {
    width: 100%;
  }
  .table-content-container
    .vessel-detail-main-container
    .route-action-container {
    flex-flow: column;
  }

  .table-content-container
    .vessel-detail-main-container
    .route-action-container
    .vessel-detail-container {
    width: auto;
  }

  .main-left-container .login-image img {
    width: 100%;
    height: 100%;
  }

  .login_left_container .login-image {
    height: auto;
  }

  .btn-table-header {
    flex-wrap: wrap !important;
    flex-direction: row !important;
    align-items: center;
  }

  .legislativ-scenario-container .scenario-container {
    padding: 1em;
  }
  .legislativ-scenario-container .scenario-container .scenario-add-container {
    flex-wrap: wrap;
  }

  .legislativ-scenario-container
    .scenario-container
    .scenario-add-container
    .input-container {
    width: 100%;
  }

  .legislativ-scenario-container
    .scenario-container
    .scenario-add-container
    .add-btn {
    margin-top: 0.5em;
  }

  .main-container .login-image {
    height: 350px;
    margin-bottom: 40px;
  }
  .main-container .main-container-actions button {
    width: 45% !important;
  }

  .refine-price-container .refine-price-tab-header .action-container .priceby,
  .refine-price-container
    .refine-price-tab-header
    .action-container
    .fuel-origin {
    width: 100%;
  }

  .refine-price-container
    .refine-price-tab-header
    .action-container
    .fuel-origin {
    display: block;
  }
}

@media only screen and (max-width: 1620px) {
  .table-header {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 1199px) {
  .btn-table-header .actions {
    width: 100%;
    flex: 1;
  }
  .btn-table-header {
    width: 100%;
  }
  .main-container {
    flex-wrap: wrap;
  }
  .main-container .main-container-actions {
    flex-wrap: wrap;
    width: 55%;
  }
  .main-container .main-container-actions button {
    width: auto;
  }

  .eu-content-container .input-container .first-container {
    flex-wrap: wrap;
  }
  .eu-content-container .input-container .first-container .cost-of-compliance,
  .eu-content-container .input-container .growth-pa,
  .eu-content-container .input-container .first-container .base-year {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .btn-table-header {
    justify-content: flex-start;
  }
  .btn-table-header .actions {
    width: 48%;
    flex: 0 0 48%;
  }
}

@media (max-width: 399px) {
  .table-detail-inner-container {
    padding-right: 0;
    overflow-x: hidden;
  }

  .forgot-password-container h2 {
    font-size: 2em;
    margin-top: 2em;
  }

  .table-content-container .vessel-detail-container .header {
    display: block;
  }

  .route-detail-container.port {
    display: block;
  }
  .tab-content-title.fuel {
    display: block;
  }

  .imo-container .carbon-input-container .levy-amount-container img {
    width: 5em;
  }

  .main-container .main-container-actions button {
    width: 100% !important;
  }
  .main-container {
    padding: 0;
    padding-bottom: 2em;
  }
  .main-container .login-image {
    height: 350px;
    margin-bottom: 0px;
    padding: 0;
  }

  .btn-table-header {
    display: block;
  }

  .table-header .action-container .btn-table-header .actions {
    width: 100%;
  }
}
